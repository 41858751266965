import axios from "axios"
import urlencode from "urlencode"
import _ from 'lodash'

import { MBM_ADMIN_COOKIE } from "../config/constants"
import transformBody from "./interceptors/transformBody"
import {getCookieValue} from './apiHelpers.js'
import {flattenPrescription, pumpPrescription} from './dataHelpers/prescription'

import {
    getAutocompleteUrl,
    getListEndpoingCfg,
    normaliseListPayload,
    getTotal
} from './getListHelpers'

export default class MiloApi {
    // @security API is initialised with proper access token
    constructor() {
        this.token = "Bearer " + getCookieValue(MBM_ADMIN_COOKIE)

        this.url = process.env.REACT_APP_MBM_ADMIN_API_URL

        this.api = transformBody(
            axios.create({
                baseURL: this.url,
                headers: {
                    Authorization: this.token
                }
            })
        )
    }

    // Provides content of autocomplete patient/prescriber dropdowns
    getPersonAutocomplete = (resourceName, {namePart}) => {
        const headers = {
            range : `${resourceName}: 0-50`
        }
        return this.api
            .get(`${resourceName}/autocomplete/${namePart}`, {headers})
            .then( ({data}) => ({data}))
    }

    getList = (resourceName, params) => {
        const acUrl = getAutocompleteUrl(resourceName, params)

        // simplest case - autocomplete requests need nothing fancy
        if (acUrl) {
            return this.api.get(acUrl)
        }

        const {url : listUrl, headers} = getListEndpoingCfg(resourceName, params)

        // some API responses need to be "unwrapped", some objects 'flattened'
        // if we have range info, we need to give it to RA for pagination
        const postProc = (response) => {
            const data = normaliseListPayload(response, resourceName)
            const total = getTotal(response)

            return { data, total }
        }

        return this.api
            .get(listUrl, {headers})
            .then(postProc)
    }

    getMany = (resourceName, {ids}) => {

        return this.api
            .get( `${resourceName}/${ids[0]}` )
            .then( ({data}) => ({data : [data]}) )
    }

    getOne = (resourceName, {id}) => {

        return this.api
            .get(`${resourceName}/${id}`)
            .then( ({data}) => {
                if (resourceName === 'prescriptions') {
                    return {data : flattenPrescription(data)}
                } else if (resourceName === 'patients') {
                    // @HACK nested patient data
                    const newData = _.merge(
                        {},
                        data,
                        data.account || {
                            email : ''
                        },
                        data.patient  || {}
                    )
                    console.log('newData', newData)
                    return {data : newData}
                } else {
                    return {data}
                }
            })
    }

    create = (resourceName, {data : payload}) => {
        return this.api
            .post(resourceName, payload)
            .then( ({data}) => ({data}) )
    }


    update = (resourceName, {id, data : rawPayload}) => {

        const payload = resourceName !== 'prescriptions'
            ? rawPayload
            : pumpPrescription(rawPayload).prescription

        return this.api
            .put(resourceName, payload)
            .then(postProcess)

        function postProcess ({data}) {
            return (resourceName !== 'prescriptions')
                ? {data}
                : {data: flattenPrescription(data)}
        }

    }

    // @business_logic
    merge = (sourcePatientId, targetPatientId) => this.api
        .post("patients/merge", {
            merge_patient: sourcePatientId,
            into_patient: targetPatientId
        })
        .then( ({data}) => data)

    // @business_logic
    sendFax = (transferId, fax) => this.api
        .post( "transfer_refax", {transfer_id : transferId, fax})
        .then( ({data}) => data)

    // @business_logic
    updateTransfer = (prescription, transfer) => {
        return this.api
            .put(`prescriptions/${prescription.id}/transfers`, transfer)
            .then( ({data}) => data)
    }

    // Provides list of values for drug form on 'new Rx' page
    getDrugNameAutocomplete = (partialDrugName) => this.api
        .get(`drugs/autocomplete/${urlencode(partialDrugName)}`)
        .then( ({data}) => data)

    getDrugPackages = (drugName) => this.api
        .get(`drugs/packages/${urlencode(drugName)}`)
        .then( ({data}) => data)


    // Some forms pull contents of 'state' dropdowns from here
    getUsStates = () => this.api
        .get("enums")
        .then( ({data : {us_states}}) => us_states)

    // Some forms pull contents of 'drug form' dropdown from here
    getDrugForms = () => this.api
        .get("enums")
        .then( ({data : {drug_forms}}) => drug_forms)

    // Prescription view has a list of transfers, each transfer has a number
    // of PDFs, this handle loads it from server, injects a download link into
    // DOM and «clicks» it programmatically to save file locally.
    downloadFax = (prescriptionId, faxId) => {
        const anchor = document.createElement("a")
        document.body.appendChild(anchor)
        const file = `${this.url}/prescriptions/${prescriptionId}/faxes/${faxId}`

        const headers = new Headers()
        headers.append("Authorization", this.token)

        return fetch(file, { headers })
            .then((response) => response.blob())
            .then((blob) => {
                const objectUrl = window.URL.createObjectURL(blob)

                anchor.href = objectUrl
                anchor.download = `${faxId}.pdf`
                anchor.click()

                window.URL.revokeObjectURL(objectUrl)
            })
    }

    // Data for transfer list on prescription's page
    getTransfersOf = (prescriptionId) =>
        this.api
            .get(`prescriptions/${prescriptionId}/transfers`)
            .then(({ data }) => data)

    // @deadcode
    // getPrescriptionsByName = (firstName, lastName) =>
    //     this.api
    //         .get(
    //             "prescriptions/by_patient_name",
    //             {
    //                 params: {
    //                     first_name: firstName,
    //                     last_name: lastName
    //                 }
    //             }
    //         )
    //         .then(({ data }) => data)

    // @business_logic
    // getPrescriptionsByDateOfBirth = (dob) =>
    //     this.api
    //         .get(`prescriptions/by_dob/dob=${dob}`)
    //         .then(({ data }) => data)

    // @deadcode
    // confirmCancelTransfer = (transferId) =>
    //     this.api
    //         .post(`mark_transfer_cancelled/${transferId}`)
    //         .then(({ data }) => data)

    downloadCsv = () => {
        this.api
            .get("prescriptions_report", { responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", "Prescriptions Report.csv")
                document.body.appendChild(link)
                link.click()
            })
    }
}
