import React from "react";
import {
    Create,
    Datagrid,
    Edit,
    List,
    Show,
    Filter,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from "react-admin";
import UsStateEnum from "./widgets/UsStateEnum";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
// import MultiTextInput from "./widgets/core/MultiTextInput";

// for style overrides
import { makeStyles } from '@material-ui/core/styles';

export const PrescriberIcon = LocalHospitalIcon;

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property
const boldHeaderText = makeStyles({
    headerCell: {
        fontWeight: 'bold',
    },
});

function PrescribersFiltersNew (props) {
    // NB: `alwaysOn` prevents hiding of filter field
    return (<Filter {...props}>
        <TextInput alwaysOn resettable label='Name'    source='name' />
        <TextInput alwaysOn resettable label='City'    source='city'/>
        <TextInput alwaysOn resettable label='Zip'     source='zipcode' />
    </Filter>)
}

export const PrescriberList = (props) => {
    const headerClasses = boldHeaderText();
    return (
        <List filters={<PrescribersFiltersNew />} bulkActionButtons={false} sort={{ field: "created_at", order: "DESC" }} {...props}>
            <Datagrid classes={headerClasses} rowClick="show">
                <TextField source="last_name" />
                <TextField source="first_name" />
                <TextField source="suffix" />
                <TextField source="clinic" />
                <TextField source="address" />
                <TextField source="city" />
                <TextField source="state" />
                <TextField source="zipcode" />
                <TextField source="phone" />
                <TextField source="fax" />
                <TextField source="npi" label="NPI"/>
                <TextField source="dea_number" label="DEA No."/>
                <TextField source="state_license" />
            </Datagrid>
        </List>
    )
};

const PrescriberTitle = ({ record }) => {
    return <span>Prescriber {record ? `"${record.first_name} ${record.last_name}"` : ""}</span>
};

export const PrescriberShow = (props) => (
    <Show title={<PrescriberTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="suffix" />
            <TextField source="clinic" />
            <TextField source="street_address" />
            <TextField source="city" />
            <TextField source="state" />
            <TextField source="zipcode" />
            <TextField source="phone" />
            <TextField source="fax" />
            <TextField source="npi" />
            <TextField source="dea_number" />
            <TextField source="state_license" />
        </SimpleShowLayout>
    </Show>
);

export const PrescriberEdit = (props) => (
    <Edit title={<PrescriberTitle />} {...props}>
        <SimpleForm>
            <TextInput   source="id" disabled />
            <TextInput   source="first_name" />
            <TextInput   source="last_name" />
            <TextInput   source="suffix" />
            <TextInput   source="clinic" />
            <TextInput   source="street_address" multiline />
            <TextInput   source="city" />
            <UsStateEnum source="state" />
            <TextInput   source="zipcode" />
            <TextInput   source="phone" />
            <TextInput   source="fax" />
            <TextInput   source="npi" />
            <TextInput   source="dea_number" />
            <TextInput   source="state_license" />
        </SimpleForm>
    </Edit>
);

export const PrescriberCreate = (props) => (
    <Create title="Create a Prescriber" {...props}>
        <SimpleForm>
            <TextInput   source="first_name" />
            <TextInput   source="last_name" />
            <TextInput   source="suffix" />
            <TextInput   source="clinic" />
            <TextInput   source="street_address" multiline />
            <TextInput   source="city" />
            <UsStateEnum source="state" />
            <TextInput   source="zipcode" />
            <TextInput   source="phone" />
            <TextInput   source="fax" />
            <TextInput   source="npi" />
            <TextInput   source="dea_number" />
            <TextInput   source="state_license" />
        </SimpleForm>
    </Create>
);
