import { object, string, number, date } from 'yup';

export default object({
    prescriber_id          : object({label: string(), value: string()}).nullable().required(),
    patient_id             : object({label: string(), value: string()}).nullable().required(),
    drug_name_autocomplete__DO_NOT_SUBMIT :
                             object({label: string(), value: string()}).nullable().required(),
    drug                   : string().required(),
    form                   : string().required(),
    dosage                 : string().required(),
    quantity               : string().required(),
    ndc                    : string().required(),
    refills_authorized     : number().positive().required(),
    fills_remaining        : number().positive().required(),
    directions             : string().required(),
    comments_to_pharmacist : string().required(),
    // duration               : number().positive().required(),
    notes                  : string().required(),
    sent_at                : date().required(),
}).required();
