import React, {
    useEffect,
    useReducer,
    useState
} from 'react'
import _ from 'lodash'

//     return <Redirect to="/prescriptions" />
// from Form to switch views
import { Redirect } from 'react-router'

// Form state management
import { useForm, Controller } from 'react-hook-form'
import { miloApi } from 'src/api'

// Stuff for drug selector block
import DrugNameAutocomplete from "./DrugNameAutocomplete"
import MdsStepSelect from './MdsStepSelect'// !!! NB: MDS is Multistage Drug Selector
import {
    reducer as mdsReducer,
    initialState as mdsInitialState
} from './mdsStateUtils'

// revert to MUI components
import {
    FormControlLabel,
    Button, CardContent, TextField,
    Checkbox
} from '@material-ui/core'

// datepicker stuff
import DateFnsUtils from '@date-io/date-fns'
import moment from "moment"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'


// Custom stuff
import FormControlStyled from './FormControlStyled'
import PersonAutocomplete from './PersonAutocomplete'

// scheme-based form validation
import { yupResolver } from '@hookform/resolvers/yup'
import newRxFormValidationSchema from './validationSchema.js'

// occasionally, we get screwed up data with missing fields, thus this little util
import normaliseDrugPackages from './normaliseDrugPackages'

function BarebonesRxForm({props}) {

    const [createdRxId, setCreatedRxId] = useState(null)

    const formConfig = {
        resolver: yupResolver(newRxFormValidationSchema),
        defaultValues : {
            prescriber_id : null,
            patient_id: null,
            drug_name_autocomplete__DO_NOT_SUBMIT : null,

            drug : '',
            form : '',
            dosage : '',
            quantity : '',
            ndc : '',

            refills_authorized : '',
            fills_remaining : '',
            directions : '',
            comments_to_pharmacist : '',
            // TODO for some reason we have two of those
            // quantity_alt : '',
            deliver_as_written : '',
            // duration : '',
            notes : '',
            sent_at : new Date(),
        }
    }
    const {control, handleSubmit, watch, setValue, formState : {errors}, trigger : triggerFormValidation} = useForm(formConfig)

    // DEBUG TODO parse form data and poke it into API, then switch to list view
    function massageAndPostToServer (fieldsStateRaw) {

        // Do not submit original stuff
        let {
            prescriber_id : {value : prescriber_id},
            patient_id : {value : patient_id},

            drug : description,
            form : strength,
            dosage : delivery_form,

            quantity,
            ndc,

            refills_authorized,
            fills_remaining,
            directions,
            comments_to_pharmacist,
            deliver_as_written,
            // duration,
            notes,
            sent_at : sent_at_raw /// !!!!!
        } = fieldsStateRaw

        // massage original stuff into submittable shape
        const data = {
            prescriber_id,
            patient_id,

            description,
            strength,
            delivery_form,
            quantity,
            ndc,

            refills_authorized,
            fills_remaining,
            directions,
            comments_to_pharmacist,
            deliver_as_written,
            // duration,
            notes,
            sent_at : moment(sent_at_raw).format("YYYY-MM-DD")
        }

        miloApi
            .create('prescriptions', {data})
            .then(({data : {prescription: {id}}}) => setCreatedRxId(id))
    }

    // what user selected from autocomplete 'dropdown' (populated by backend's response)
    const [drugFilterState, drugFilterDispatch] = useReducer(mdsReducer, mdsInitialState)

    const drugNameAutocomplete = watch('drug_name_autocomplete__DO_NOT_SUBMIT')

    // MDS initialisation, stage 1.
    // When user autocompletes drug, we load data from backend and
    // _prepage_ MDS for operation
    useEffect( () =>  {
        (drugNameAutocomplete && drugNameAutocomplete.value)
            && triggerFormValidation()
        if (drugNameAutocomplete && drugNameAutocomplete.value?.length > 2) {
            miloApi
                .getDrugPackages(drugNameAutocomplete.value)
                .then(normaliseDrugPackages)
                // We stash default package, because updated state will be available
                // in the next render cycle -- THEN we'll poke MDS with first refilter
                // request using data from 'defaultPackage we've stasned
                .then(({defaultPackage, drugs}) => {
                    drugFilterDispatch({
                        type : 'init',
                        payload : {defaultPackage, drugs}
                    })
                })
        }
    }, [drugNameAutocomplete])

    // MDS initialisation, stage 2. After useEffect above finalizes,
    // data become available for filtration.
    useEffect(() => {
        // plug filter values into MDS selects, one by one
        if (drugFilterState.filters) {
            const {drug, form, dosage, quantity} = drugFilterState.filters

            const {quantities} = drugFilterState.filtered
            const hasQuantities = quantities.length > 0
            const isQuanitySet = quantity && String(quantity).length > 0
            const ndcExtracted = hasQuantities && isQuanitySet
                ? _.filter(quantities, ({quantity: oldQuantity}) => String(oldQuantity) === String(quantity))[0]['ndc']
                : ''

            const ordered = [
                ['drug', drug],
                ['form', form],
                ['dosage', dosage],
                ['quantity', quantity],
                ['ndc', ndcExtracted]
            ]

            _.forEach(ordered, ([name, value]) => setValue(name, value))

            triggerFormValidation()
        }
    }, [drugFilterState, setValue])

    if (createdRxId) {
        return <Redirect to={`/prescriptions`} />
    }

    // @HACK quick and dirty styling for error messages
    // -- should use proper MUI component
    const Error = ({name, children}) => {
        return errors[name] && errors[name].message
            ? <p style={{color: 'red', paddingLeft: '50px', marginTop: '-15px', marginBottom: '0'}}>{children}</p>
            : null
    }

    return (<form onSubmit={handleSubmit(massageAndPostToServer)}>
        <CardContent>
            {/*prescriber_id*/}
            <FormControlStyled>
                <Controller
                    name="prescriber_id"
                    control={control}
                    render={
                        // field: {onChange, onBlur, value, name}
                        ({field:{onChange, value}}) => <PersonAutocomplete
                            value={value}
                            onChange={onChange}
                            resourceName='prescribers'
                            label='Prescriber'
                        />
                    }
                />
            </FormControlStyled>

            <Error name='prescriber_id'>Please select a prescriber.</Error>

            {/*patient_id*/}
            <FormControlStyled>
                <Controller
                    name="patient_id"
                    control={control}
                    render={
                        // field: {onChange, onBlur, value, name}
                        ({field:{onChange, value}}) => <PersonAutocomplete
                            value={value}
                            onChange={onChange}
                            resourceName='patients'
                            label='Patient'
                        />
                    }
                />
            </FormControlStyled>

            <Error name='patient_id'>Please select a patient.</Error>

            {/*drug_name_autocomplete__DO_NOT_SUBMIT*/}
            <FormControlStyled>
                <Controller
                    name="drug_name_autocomplete__DO_NOT_SUBMIT"
                    control={control}
                    render={
                        // field: {onChange, onBlur, value, name}
                        ({field:{onChange, value}}) => <DrugNameAutocomplete
                            label='Drug search'
                            value={value}
                            onChange={onChange}
                        />
                    }
                />
            </FormControlStyled>

            <Error name='drug_name_autocomplete__DO_NOT_SUBMIT'>First, use «Drug search» to choose a drug being prescribed.</Error>

            {/*MDS -- drug*/}
            <FormControlStyled>
                <MdsStepSelect
                    label='Name'
                    name='drug'
                    control={control}
                    dispatch={drugFilterDispatch}
                    state={drugFilterState.filtered.drugs}
                />
            </FormControlStyled>

            <Error name='drug'>Select «Drug» name, please.</Error>

            {/*MDS -- delivery_form*/}
            <FormControlStyled>
                <MdsStepSelect
                    label='Form'
                    name='form'
                    control={control}
                    dispatch={drugFilterDispatch}
                    state={drugFilterState.filtered.forms}
                />
            </FormControlStyled>

            <Error name='form'>Select «Form», please.</Error>


            {/*MDS -- strength*/}
            <FormControlStyled>
                <MdsStepSelect
                    label="Dosage"
                    name='dosage'
                    control={control}
                    dispatch={drugFilterDispatch}
                    state={drugFilterState.filtered.dosages}
                />
            </FormControlStyled>

            <Error name='dosage'>Select «Dosage», please.</Error>

            {/*MDS -- quantity*/}
            <FormControlStyled>
                <MdsStepSelect
                    label='Quantity'
                    name='quantity'
                    control={control}
                    dispatch={drugFilterDispatch}
                    state={drugFilterState.filtered.quantities}
                />
            </FormControlStyled>

            <Error name='quantity'>Select «Quantity», please.</Error>

            {/*refills_authorized*/}
            <FormControlStyled>
                <Controller
                    name="refills_authorized"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <TextField
                            value={value}
                            onChange={onChange}
                            label="Refills authorised"
                            type="tel"
                            variant="filled"
                            style={{width:'100%'}}
                        />
                    }
                />
            </FormControlStyled>

            <Error name='refills_authorized'>«Refills authorised» should be a number (more than zero).</Error>

            {/*fills_remaining*/}
            <FormControlStyled>
                <Controller
                    name="fills_remaining"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <TextField
                            value={value}
                            onChange={onChange}
                            label="Fills remaining"
                            type="tel"
                            variant="filled"
                            style={{width:'100%'}}
                        />
                    }
                />
            </FormControlStyled>

            <Error name='fills_remaining'>«Fills remaining» should be a number (more than zero).</Error>

            {/*directions*/}
            <FormControlStyled  width='80%'>
                <Controller
                    name="directions"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <TextField
                            value={value}
                            onChange={onChange}
                            label="Directions"
                            multiline
                            minRows={3}
                            maxRows={10}
                            variant="filled"
                            style={{width:'100%'}}
                        />
                    }
                />
            </FormControlStyled>

            {/*comments_to_pharmacist*/}
            <FormControlStyled  width='80%'>
                <Controller
                    name="comments_to_pharmacist"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <TextField
                            value={value}
                            onChange={onChange}
                            label="Comments to pharmacist"
                            multiline
                            minRows={3}
                            maxRows={10}
                            variant="filled"
                            style={{width:'100%'}}
                        />
                    }
                />
            </FormControlStyled>

            {/*deliver_as_written*/}
            <FormControlStyled>
                <Controller
                    name="deliver_as_written"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <FormControlLabel
                            label="DAW"
                            control={
                                <Checkbox
                                    checked={!!value}
                                    onChange={({target : {checked : isChecked}}) => onChange(isChecked)}
                                    color="primary"
                                />
                            }
                        />
                    }
                />
            </FormControlStyled>

            {/*notes*/}
            <FormControlStyled width='80%'>
                <Controller
                    name="notes"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <TextField
                            value={value}
                            onChange={onChange}
                            label="Notes"
                            multiline
                            minRows={3}
                            maxRows={10}
                            variant="filled"
                            style={{width:'100%'}}
                        />
                    }
                />
            </FormControlStyled>

            {/*sent_at*/}
            <FormControlStyled>
                <Controller
                    name="sent_at"
                    control={control}
                    render={
                        ({field:{value, onChange}}) => <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Date recieved"
                                    format="MM/dd/yyyy"
                                    value={value}
                                    onChange={onChange}
                                    style={{width:'100%'}}
                                />
                            </MuiPickersUtilsProvider>
                    }
                />
            </FormControlStyled>

            <Error name='sent_at'>Prescription needs a submission date.</Error>

            {/*submit control*/}
            <FormControlStyled>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
            </FormControlStyled>
        </CardContent>
    </form>)
}

export default BarebonesRxForm
