import React, { useState } from "react";
import TypeaheadInput from "./TypeaheadInput";
import _ from "lodash";

const DynamicEnumInput = ({ source, label, getOptions, ...rest }) => {
    const [options, setOptions] = useState(null);

    const getData = () => {
        return options
            ? Promise.resolve(options)
            : getOptions().then((data) => {
                    setOptions(data);
                    return data;
                });
    };

    return (
        <TypeaheadInput
            source={source}
            label={label}
            onInputChange={getData}
            getInitialValue={(form) => {
                if (_.isEmpty(form)) return undefined;
                return form[source];
            }}
            getInitialChoices={getData}
            {...rest}
        />
    );
};

export default DynamicEnumInput;
