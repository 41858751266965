import React, { forwardRef } from "react";
import ReactDOM from "react-dom";

import { miloApi } from "./api";
import authProvider from "./auth";

import { Admin, AppBar, Layout, Resource, UserMenu } from "react-admin";

import { PatientCreate, PatientEdit, PatientIcon, PatientList, PatientShow } from "./resources/patients";
import { PrescriptionCreate, PrescriptionEdit, PrescriptionIcon, PrescriptionList, PrescriptionShow } from "./resources/prescriptions";
import { PrescriberCreate, PrescriberEdit, PrescriberIcon, PrescriberList, PrescriberShow } from "./resources/prescribers";
import { PharmacyCreate, PharmacyEdit, PharmacyIcon, PharmacyList, PharmacyShow } from "./resources/pharmacies";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";

import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-responsive-modal/styles.css";


const DownloadMenuItem = forwardRef(({ onClick }, ref) => {
    const _downloadCsv = () => {
        onClick()
        miloApi.downloadCsv()
    }
    return (<ListItem button onClick={_downloadCsv} >
        <ListItemIcon style={{ minWidth: 40 }}>
            <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Download" />
    </ListItem>)
});

const MiloUserMenu = (props) => (
    <UserMenu {...props}>
        <DownloadMenuItem />
    </UserMenu>
);

// TODO check passing of usermenu - shall we pass instance OR fn?
const MiloAppBar = (props) => <AppBar {...props} userMenu={<MiloUserMenu />} />;
const MiloLayout = (props) => <Layout {...props} appBar={MiloAppBar} />;

export const MiloAdmin = () => {
    return <>
        <Admin disableTelemetry title="Milo Admin" authProvider={authProvider} dataProvider={miloApi} layout={MiloLayout}>
            <Resource   name="patients"         icon={PatientIcon}
                        list={PatientList}      show={PatientShow}
                        edit={PatientEdit}      create={PatientCreate} />

            <Resource   name="prescriptions"    icon={PrescriptionIcon}
                        list={PrescriptionList} show={PrescriptionShow}
                        edit={PrescriptionEdit} create={PrescriptionCreate} />

            <Resource   name="prescribers"      icon={PrescriberIcon}
                        list={PrescriberList}   show={PrescriberShow}
                        edit={PrescriberEdit}   create={PrescriberCreate} />

            <Resource   name="pharmacies"       icon={PharmacyIcon}
                        list={PharmacyList}     show={PharmacyShow}
                        edit={PharmacyEdit}     create={PharmacyCreate} />
        </Admin>
    </>
}
