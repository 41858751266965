import React from "react";
import _ from "lodash";

const StringArrayField = ({ record, source }) => {
    const items = _.filter(record[source], (it) => !_.isEmpty(it.trim()));

    if (_.isEmpty(items)) return null;

    return (
        <ul>
            {items.map((it) => (
                <li key={it}>{it}</li>
            ))}
        </ul>
    );
};

StringArrayField.defaultProps = {
    addLabel: true
};

export default StringArrayField;
