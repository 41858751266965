import React from "react";
import { ArrayField } from "react-admin";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { humanizeBoolean } from "./BooleanTextField";
import moment from "moment";

const PrescriptionList = ({ data }) => {
    const prescriptions = _(data)
        .orderBy("created_at", "desc")
        .value();

    if (_.isEmpty(prescriptions)) return null;

    const humanizeName = (entity) => {
        if (!entity) return null;
        const { first_name, last_name } = entity;
        return `${first_name} ${last_name}`;
    };

    return (
        <div>
            <h3>Prescriptions</h3>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Rx</b></TableCell>
                            <TableCell><b>Date Received</b></TableCell>
                            <TableCell><b>Description</b></TableCell>
                            <TableCell><b>Fills Authorized</b></TableCell>
                            <TableCell><b>Fills Transferred</b></TableCell>
                            <TableCell><b>Prescriber</b></TableCell>
                            <TableCell><b>Directions</b></TableCell>
                            <TableCell><b>Quantity</b></TableCell>
                            <TableCell><b>DAW</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prescriptions.map((it, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <a
                                            href={`/#/prescriptions/${it.prescription.id}/show`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {it.prescription.serial_number}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        {moment(it.prescription.sent_at).format("MM/DD/YYYY")}
                                    </TableCell>
                                    <TableCell>{it.prescription.description}</TableCell>
                                    <TableCell>
                                        {it.prescription.refills_authorized}
                                    </TableCell>
                                    <TableCell>
                                        {it.prescription.transfers?.filter((it) => it.status !== "cancelled")
                                            ?.length || "Not Available"}
                                    </TableCell>
                                    <TableCell>
                                        <a
                                            href={`/#/prescribers/${it.prescriber.id}/show`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {humanizeName(it.prescriber) || "Show"}
                                        </a>
                                    </TableCell>
                                    <TableCell>{it.prescription.directions}</TableCell>
                                    <TableCell>{it.prescription.quantity}</TableCell>
                                    <TableCell>
                                        {humanizeBoolean(it.prescription.deliver_as_written)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const PrescriptionTable = (props) => {
    return (
        <ArrayField {...props}>
            <PrescriptionList />
        </ArrayField>
    );
};

export default PrescriptionTable;
