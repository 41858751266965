import _ from 'lodash'

// what we get from server is nested and we need it flat
export default function normaliseDrugPackages({defaultPackage, drugs}) {

    // Server gives us a nested structure (hash-of-arrays-of-hashes-of-arrays),
    // which is nasty to work with -- ReactAdmin hates us.
    // Ergo:
    /*  - we convert it into array of flat hashes:
            -[{drug, form, dosage, quantity, ndc}]
        - by walking the following structure, depth-first:
            - each layer has a 'descriptor' field and a 'holder' array
                - (i.e., drugs[] top-level array of hashes,
                    - each hash has 'drug' string
                    - each hash has 'forms' array
                        - ... and so on, until we reach deepest level of [{quantity, ndc}] */
    // (could've used _.flatMap, but can't figure it out)
    const {flatDrugs} = _.reduce(drugs, flattenDrugForms, {stash: {}, flatDrugs: []})
    // NB: iteratees are below in reverse order, all are f-pure
    function flattenDrugForms (accum, {drug, forms}) {
        accum.stash.drug = drug
        return _.reduce(forms, flattenFormDosages, accum)
    }
    function flattenFormDosages (accum, {form, dosages}) {
        accum.stash.form = form
        return _.reduce(dosages, flattenDosageQuantities, accum)
    }
    function flattenDosageQuantities (accum, {dosage, quantities}) {
        accum.stash.dosage = dosage
        // deepest level has no nesting - no need to drill - no more reducers!
        const flatChunk = _.map(quantities, ({quantity, ndc}) => {
            const {drug, form, dosage} = accum.stash
            return _.merge({quantity, ndc}, {drug, form, dosage})
        })
        accum.flatDrugs = _.concat(accum.flatDrugs, flatChunk)
        return accum
    }

    const defPackWithExtras = _.filter(flatDrugs, (d) => {
        return d.ndc == defaultPackage.ndc
            && d.quantity == defaultPackage.quantity
    })

    const bundle = {
        // MOCK defaultPackages lack form and dosage, therefore...
        defaultPackage: defPackWithExtras[0],
        drugs,
        // flatDrugs
    }

    return Promise.resolve(bundle)
}
