import React from "react"

import {
    Create, Edit, EditButton, Filter, List,
    Datagrid, Show, SimpleShowLayout, TopToolbar,
    DateField, NumberField, TextField,
    SimpleForm, required,
    NumberInput, BooleanInput, DateInput, TextInput,
    Toolbar,
    SaveButton,
    DeleteButton
} from "react-admin";

import Button from "@material-ui/core/Button";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles'; // for style overrides

import DrugFormEnum from "./widgets/DrugFormEnum";
import TransferTable from "./widgets/TransferTable";
import TextAreaInput from "./widgets/TextAreaInput";
import BooleanTextField from "./widgets/BooleanTextField";

// a component for experimental barebones form handling
import BarebonesFxForm from './widgets/newPrescriptionForm/BarebonesRxForm';

// links from prescription detail view to other pages
import {PatientLink, PrescriberLink} from './widgets/customFields/linksToStuff.js'

export const PrescriptionIcon = ReceiptIcon; // FUR do we use it anywhere?

// for PrescriptionShow, see below
const stylesPrescriptionShow = {
    columnLayout: {
        display: "flex"
    }
};

// for 'notes' field
const styleTextField = { wordBreak: "break-word" };

const fauxFieldNoWrap = {whiteSpace: 'nowrap'};

const PrescriptionListFilters = (props) => {
    return (
        <Filter {...props}>
            {/*<TextInput alwaysOn resettable placeholder="YYYY-MM-DD"
                label="Date of Birth" source="patient__dob" />*/}
            <DateInput alwaysOn           placeholder="MM/DD/YYYY"
                source="patient__dob"  label="Date of Birth"  />
            <TextInput alwaysOn resettable
                source="patient__full_name" label="Patient" />
            <TextInput alwaysOn resettable
                source="prescriber__full_name" label="Prescriber"/>
            <TextInput alwaysOn resettable
                source="prescription__serial_number" label="Serial no." />
        </Filter>
    )
};

export const PrescriptionList = (props) => {
    const boldHeaders = makeStyles({
        headerCell: {
            fontWeight: 'bold',
        },
    });
    return (
        <List filters={<PrescriptionListFilters />} sort={{ field: "prescription__sent_at", order: "DESC" }} bulkActionButtons={false} perPage={25} {...props} >
            <Datagrid classes={boldHeaders()} rowClick="show">
                <TextField    source="prescription__serial_number"        label="Rx" />
                <DateField    source="prescription__sent_at"              label="Received" />
                <TextField    source="patient__full_name"                 label='Patient' style={fauxFieldNoWrap}/>
                <DateField    source="patient__dob"                       label='DoB' style={fauxFieldNoWrap}/>
                <TextField    source="prescription__description"          label='Description'/>
                <TextField    source="prescription__strength"             label="Strength" style={fauxFieldNoWrap}/>
                <TextField    source="prescription__delivery_form"        label="Form"/>
                <NumberField  source="prescription__refills_authorized"   label="Refills auth." />
                <NumberField  source="prescription__fills_remaining"      label="Fills rem."/>
                <NumberField  source="prescription__quantity"             label="Quantity"/>
                <TextField    source="prescription__duration"             label="Days supply" />
            </Datagrid>
        </List>
    )
};

const PrescriptionTitle = ({ record }) => {
    return (
        <span>
            Prescription
            {" "}
            {record ? `"${record.prescription__serial_number}: ${record.prescription__description}` : ""}
        </span>
    );
};

const PrescriptionActions = ({ basePath, data, resource }) => {
    const printButton = data && (
        <div style={{ marginLeft: 10 }}>
            <Button variant="contained" color="default" onClick={() => { window.print(); }}>Print</Button>
        </div>
    )

    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            {printButton}
        </TopToolbar>
    );
};


const __PrescriptionShowBasic = ({ classes, hasShow, ...props }) => {

    const TransferTableWrapper = (props) => {
        const { record: prescription } = props;
        const hasTransfers = prescription.__original_prescription_object__.transfers !== undefined
        return hasTransfers
            ? <TransferTable prescription={prescription} />
            : null
    };

    return (
        <Show title={<PrescriptionTitle />} actions={<PrescriptionActions />} {...props} >
            <SimpleShowLayout>
                <SimpleShowLayout className={classes.columnLayout}>
                    <SimpleShowLayout>

                        <PatientLink        {...props} />
                        <TextField          source="patient__dob" label='Date of birth'/>
                        <DateField          source="prescription__sent_at" label="Date Received" />
                        <TextField          source="prescription__description" label="Description" />
                        <TextField          source="prescription__strength" label="Strenght"/>
                        <NumberField        source="prescription__quantity" />
                        <TextField          source="prescription__delivery_form" />
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <TextField          source="prescription__serial_number" label="Seial no." />

                        <PrescriberLink     {...props}/>

                        <NumberField        source="prescription__refills_authorized" label="Refills Authorized" />
                        <NumberField        source="prescription__fills_remaining" label="Fills remaining" />

                        <BooleanTextField   source="prescription__deliver_as_written" label="DAW" />
                        <TextField          source="prescription__duration" label="Days Supply" />
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <TextField          source="prescription__directions" />
                        <TextField          source="prescription__comments_to_pharmacist" label="Comments to pharmacist"  />
                        <TextField          source="prescription__notes" style={styleTextField} />
                    </SimpleShowLayout>
                </SimpleShowLayout>

                <TransferTableWrapper />
            </SimpleShowLayout>
        </Show>
    );
}
export const PrescriptionShow = withStyles(stylesPrescriptionShow)(__PrescriptionShowBasic);

const PrescriptionEditToolbar = (props) => (
    <Toolbar {...props}
        // classes={useStyles()}
    >
        <SaveButton />
    </Toolbar>
);


export const PrescriptionEdit = (props) => {
    const req = [required()]
    return (
        <Edit undoable={false} title={<PrescriptionTitle />} {...props}>
            <SimpleForm toolbar={ <PrescriptionEditToolbar/> }>
                <NumberInput    source="prescription__serial_number" label="Serial no." disabled />
                <TextInput      source="patient__full_name" disabled/>
                <TextInput      source="patient__dob" disabled/>
                <TextInput      source="prescription__description"  label='Description' validate={req} />
                <TextInput      source="prescription__strength" label='Strength' validate={req} />
                <DrugFormEnum   source="prescription__delivery_form"  validate={req} />
                <TextInput      source="prescription__ndc" label="NDC" validate={req} />

                <NumberInput    source="prescription__refills_authorized" label="Refills authorized" validate={req} />
                <NumberInput    source="prescription__fills_remaining" label="Fills remaining" validate={req} />

                <TextInput      source="prescription__directions" label="Directions" validate={[required()]} multiline />
                <TextAreaInput  source="prescription__comments_to_pharmacist" label="Comments to pharmacist" multiline style={styleTextField} />
                <NumberInput    source="prescription__quantity" label="Quantity" validate={req} />
                <BooleanInput   source="prescription__deliver_as_written" label="DAW"  />
                <TextInput      source="prescription__duration" label="Days Supply" />
                <TextAreaInput  source="prescription__notes" />
                <DateInput      source="prescription__sent_at" label="Date Received"  validate={req}/>
            </SimpleForm>
        </Edit>
    )
}

export const PrescriptionCreate = (props) => {

    return (
        <Create title="Create a Prescription" {...props}>
            <BarebonesFxForm/>
        </Create>
    )
};
