import React, { useState } from "react";
import { SimpleForm } from "react-admin";
import { miloApi } from "src/api";
import _ from "lodash";
import TypeaheadInput from "./core/TypeaheadInput";

const MergeForm = ({ sourcePatientId, onMerge }) => {
    const [selectedPatient, setSelectedPatient] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const merge = () => {
        setIsLoading(true);

        miloApi
            .merge(sourcePatientId, selectedPatient.id)
            .then(() => {
                onMerge();
                window.location.href = `/#/patients/${selectedPatient.id}/show`;
            })
            .catch(() => {
                alert("Unexpected error!");
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <h3 style={{ marginTop: 25 }}>Merge into another patient record...</h3>

            <SimpleForm save={merge} saving={isLoading}>
                <TypeaheadInput
                    source={"patients"}
                    label="Name"
                    onInputChange={(value) => {
                        if (value.length < 3) return Promise.resolve([]);

                        return miloApi
                            .getList("patients", { filter: { last_name: value } })
                            .then(({ data }) => data);
                    }}
                    onChange={(patient) => {
                        if (_.isString(patient)) return;

                        setSelectedPatient(patient);
                    }}
                    getOptionValue={(it) => {
                        return it.id;
                    }}
                    getOptionLabel={(it) => {
                        return `${it.first_name} ${it.last_name} (${it.dob})`;
                    }}
                    style={{ marginBottom: 20 }}
                />
            </SimpleForm>
        </div>
    );
};

export default MergeForm;
