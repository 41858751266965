import { TextInput } from "react-admin";
import React from "react";

const TextAreaInput = (props) => (
	<TextInput multiline options={{ minRows: 4, maxRows: 10000 }} {...props} />
);

TextAreaInput.defaultProps = {
	fullWidth: true
};

export default TextAreaInput;
