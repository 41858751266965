import React, {useState, useEffect} from "react"
import _ from "lodash"
import { miloApi } from "src/api"

// Reference:
// https://v4.mui.com/components/autocomplete/#autocomplete
import { Autocomplete } from "@material-ui/lab"
import { TextField as MuiTextField } from "@material-ui/core"


// "PatientPrescriber Autocomplete" component
// -- used in "New Prescription" form
// --
export default function PersonAutocomplete ({value, onChange, resourceName, label, variant='filled'}) {

    const [persons, setPersons] = useState([])
    const [namePart, setNamePart] = useState('')

    // As soon as we get anything in the text field - load options
    useEffect( () => {
        if (namePart.length > 0) {
            miloApi
                .getPersonAutocomplete(resourceName, {namePart})
                .then(({data}) => setPersons(getFormattedOptions(data)))
        } else {
            setPersons([])
        }
        return () => setNamePart('')
    }, [namePart])

    // massage data from server into RA-acceptable shape
    function getFormattedOptions(data) {
        const sorted = _.sortBy(data, ['last_name'])
        const formatted = _.map(sorted, ({id, first_name, last_name, middle_name}) => ({
            value: id,
            label: `${last_name}, ${first_name} ${middle_name || ''}`.trim()
        }))
        return formatted
    }

    return (<>
        <Autocomplete
            value={value}
            onChange={(event, newValue) => onChange(newValue)}

            options={persons}

            getOptionSelected={(option, value) => {
                return option.id === value.id
            }}

            getOptionLabel={({label}) => label}

            renderInput={
                (params) => {
                    // override value & onChange passed by wrapping component
                    const paramsWithOverrides = {
                        ...params,
                        value : namePart,
                        onChange : ({target : {value : newVal }}) => setNamePart(newVal)
                    }
                    return <MuiTextField label={label} variant={variant}
                        {...paramsWithOverrides}
                    />
                }
            }
        />
    </>)
}
