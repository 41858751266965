import React, { useState } from "react"
import _ from "lodash"
import {
    ArrayInput, BooleanInput, DateInput,
    Datagrid, EditButton,
    Create,Edit, Filter, List, Show,
    SimpleForm, SimpleFormIterator, SimpleShowLayout,
    TextField, TextInput, TopToolbar
} from "react-admin"

import UsStateEnum from "./widgets/UsStateEnum"
import GenderEnum from "./widgets/GenderEnum"
import MergeForm from "./widgets/MergeForm"
import PrescriptionTable from "./widgets/PrescriptionTable"
import AccessibilityIcon from "@material-ui/icons/Accessibility"
import MultiTextInput from "./widgets/core/MultiTextInput"
import TextAreaInput from "./widgets/TextAreaInput"
import StringArrayField from "./widgets/StringArrayField"
import DateField from "./widgets/DateField"
import BooleanTextField from "./widgets/BooleanTextField"
import Button from "@material-ui/core/Button"
import { styleForMinCharacters } from "../utils/styleUtils"
import { withStyles } from "@material-ui/core/styles"

// for style overrides
import { makeStyles } from '@material-ui/core/styles'

export const PatientIcon = AccessibilityIcon;

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property
const boldHeaderText = makeStyles({
    headerCell: {
        fontWeight: 'bold',
    },
});

const SearchFilter = (props) => {
    // alwaysOn prevents hiding of input
    return (
        <Filter {...props}>
            <DateInput alwaysOn  source="dob"  label="Date of Birth" placeholder="MM/DD/YYYY" />
            <TextInput alwaysOn resettable source="name" label="Name"  />
        </Filter>
    )
};

export const PatientList = (props) => {
    const classes = boldHeaderText();
    return (
        <List filters={<SearchFilter />} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} {...props}>
            <Datagrid classes={classes} rowClick="show">
                <TextField source="last_name" />
                <TextField source="first_name" />
                <TextField source="mobile_phone" label="Phone" />
                <DateField source="dob" label="Date of Birth" />
                <TextField source="street_address" />
                <TextField source="city" style={styleForMinCharacters(15)} />
                <TextField source="state" />
                <TextField source="zipcode" />
            </Datagrid>
        </List>
    )
};

const PatientTitle = ({ record }) => {
    return (
        <span>Patient {record ? `"${record.first_name} ${record.last_name}"` : ""}</span>
    );
};

const PatientActions = ({ basePath, data, resource, isMerging, setIsMerging}) => {
    // TODO move logic out of template
    const button_merge = (
        <div style={{ marginLeft: 10 }}>
            <Button variant="contained" color={isMerging ? "primary" : "default"} onClick={() => { setIsMerging(!isMerging) }} >
                {"Merge"}
            </Button>
        </div>
    )

    const createRx = () => { window.location.href = `/#/prescriptions/create?source={"patient_id":"${data.id}"}` }
    const button_addRx = (
        <div style={{ marginLeft: 10 }}>
            <Button variant="contained" color="default" onClick={createRx} >
                {"Add Rx"}
            </Button>
        </div>
    )
    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            {data && button_merge}
            {data && button_addRx}
        </TopToolbar>
    );
};

// TODO split unstyled into a proper component
const displayAsFlex = {columnLayout: {display: "flex"}}
const _PatientShow_unstyled = ({ classes, hasShow, ...props }) => {
    const [isMerging, setIsMerging] = useState(false);

    const MergeFormWrapper = (props) => {
        const { record: patient } = props;
        return (
            <SimpleShowLayout>
                <MergeForm onMerge={() => setIsMerging(false)} sourcePatientId={patient.id} {...props}/>
            </SimpleShowLayout>
        );
    };

    const PrescriptionTableWrapper = (props) => {
        const { record: patient } = props;
        if (patient?.prescriptions?.length > 0) {
            return (
                <SimpleShowLayout>
                    <PrescriptionTable data={patient.prescriptions} />
                </SimpleShowLayout>
            )
        } else {
            return null;
        }
    };

    return (
        <Show title={<PatientTitle />} actions={ <PatientActions isMerging={isMerging} setIsMerging={setIsMerging} /> } {...props}>
            <SimpleShowLayout>
                <SimpleShowLayout className={classes.columnLayout}>
                    <SimpleShowLayout>
                        <TextField        source="first_name" />
                        <TextField        source="last_name" />
                        <TextField        source="gender" />
                        <TextField        source="email" />
                        <TextField        source="mobile_phone" label="Phone" />
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <DateField        source="dob" label="Date of Birth" />
                        <TextField        source="street_address" />
                        <TextField        source="city" />
                        <TextField        source="state" />
                        <TextField        source="zipcode" />
                    </SimpleShowLayout>

                    <SimpleShowLayout>
                        <TextField        source="notes" fullWidth />
                        <BooleanTextField source="pregnant" />
                        <BooleanTextField source="breastfeeding" />
                        <StringArrayField source="allergies" />
                        <StringArrayField source="current_medications" />
                        <StringArrayField source="medical_conditions" />
                    </SimpleShowLayout>

                    {isMerging && <MergeFormWrapper />}
                </SimpleShowLayout>
                <PrescriptionTableWrapper />
            </SimpleShowLayout>
        </Show>
    );
}
export const PatientShow = withStyles(displayAsFlex)(_PatientShow_unstyled);

export const PatientEdit = (props) => (
    <Edit title={<PatientTitle />} {...props}>
        <SimpleForm>
            <TextInput     source="id" disabled/>
            <TextInput     source="first_name" />
            <TextInput     source="last_name" />
            <GenderEnum    source="gender" />
            <DateInput     source="dob" label="Date of Birth" />
            <TextInput     source="email" />
            <TextInput     source="mobile_phone" label="Phone" />
            <TextInput     source="street_address" multiline />
            <TextInput     source="city" />
            <UsStateEnum   source="state" />
            <TextInput     source="zipcode" />
            <TextAreaInput source="notes" />
            <BooleanInput  source="pregnant" />
            <BooleanInput  source="breastfeeding" />

            <ArrayInput    source="allergies">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Allergy" }} />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput    source="current_medications">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Medication" }} />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput    source="medical_conditions">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Condition" }} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const PatientCreate = (props) => (
    <Create title="Create a Patient" {...props}>
        <SimpleForm>
            <TextInput     source="first_name" />
            <TextInput     source="last_name" />
            <DateInput     source="dob" label="Date of Birth" />
            <TextInput     source="email" />
            <TextInput     source="mobile_phone" label="Phone" />
            <TextInput     source="street_address" multiline />
            <TextInput     source="city" />
            <UsStateEnum   source="state" />
            <TextInput     source="zipcode" />
            <TextAreaInput source="notes" />
            <BooleanInput  source="pregnant" />
            <BooleanInput  source="breastfeeding" />

            <ArrayInput    source="allergies">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Allergy" }} />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput    source="current_medications">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Medication" }} />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput    source="medical_conditions">
                <SimpleFormIterator>
                    <TextInput options={{ label: "Condition" }} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
