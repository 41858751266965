import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Loader from "react-loader-spinner";
import { miloApi } from "src/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { styleForMinCharacters } from "../../utils/styleUtils";
import _ from "lodash";
import Icon from "feather-icons-react";
import { formatPhoneNumber } from "../../utils";

// Popuppy with fax no field, will be rendered and sit dormant
// until someone sets 'isOpen' to true
const FaxModal = ({ isOpen, onClose, transfer }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [faxNumber, setFaxNumber] = useState("");

    // Pop-up should open with empty fax no. field
    useEffect(() => {
        if (!isOpen) setFaxNumber("");
    }, [isOpen]);

    const sendFax = () => {
        setIsLoading(true);

        miloApi
            .sendFax(transfer.id, faxNumber)
            .then(() => {
                alert("Fax sent!");
                onClose();
            })
            .catch(() => {
                alert("Unexpected error!");
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Modal open={isOpen} onClose={onClose} center>
            <h3 style={{ marginTop: 50 }}>Send fax...</h3>

            <input
                value={faxNumber}
                onChange={(event) => setFaxNumber(event.target.value)}
                type="text"
                maxLength="15"
            />

            <div style={{ marginTop: 10 }}>
                {
                    // TODO: remove ternary
                    isLoading
                        ? (<Loader type="Puff" color="#CCCCCC" height={24} width={24} />)
                        : (<button onClick={sendFax} disabled={faxNumber.length === 0}>Send</button>)
                }
            </div>
        </Modal>
    );
};

// Pop-up with transfer edit
const EditModal = ({ isOpen, onClose, onReload, transfer, prescription }) => {
    // twiddly thingie state
    const [isLoading, setIsLoading] = useState(false);
    // comments field for transfer
    const [notes, setNotes] = useState("");
    const [receivingPharmacist, setReceivingPharmacist] = useState("");

    // reset when component re-renders in `opened` state
    useEffect(() => {
        setNotes(isOpen ? transfer.notes : "");
        setReceivingPharmacist(isOpen ? transfer.receiving_pharmacist : "");
    }, [isOpen, transfer]);

    // talk to backend to update transfer fields
    const update = () => {
        setIsLoading(true);

        miloApi
            .updateTransfer(prescription, {
                ...transfer,
                prescription_id: prescription.id,
                notes: notes,
                receiving_pharmacist: receivingPharmacist
            })
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                alert("Unexpected error ocurred while saving.");
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Modal open={isOpen} onClose={onClose} center>
            <h3 style={{ marginTop: 50 }}>Edit...</h3>

            <div>
                <label htmlFor="notes">Notes</label>
                <br />
                <textarea
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                    rows="10"
                    cols="50"
                />
            </div>

            <br />

            <div>
                <label htmlFor="receiving-pharmacist">Receiving pharmacist</label>
                <br />
                <input
                    id="receiving-pharmacist"
                    value={receivingPharmacist}
                    onChange={(event) => setReceivingPharmacist(event.target.value)}
                    type="text"
                    style={{ width: "100%" }}
                />
            </div>

            <div style={{ marginTop: 10 }}>
                {isLoading ? (
                    <Loader type="Puff" color="#CCCCCC" height={24} width={24} />
                ) : (
                    <button onClick={update}>Save</button>
                )}
            </div>
        </Modal>
    );
};

const TransferList = ({ prescription }) => {
    const [isLoadingFax, setIsLoadingFax] = useState(false);
    const [isFaxModalOpen, setIsFaxModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(null);

    const prescriptionId = prescription.id;

    // history of transferts
    // const sortedHistory = _(prescription.transfers)
    //     .orderBy("transfer.created_at", "asc")
    //     .value();
    const sortedHistory = _.sortBy(prescription.__original_prescription_object__.transfers, (t) => t.transfer.created_at);
    // const sortedHistory = prescription.__original_prescription_object__.transfers;

    // component is already in DOM, we just need to activate it
    const openFaxModal = (transfer) => {
        setSelectedTransfer(transfer);
        setIsFaxModalOpen(true);
    };

    // ditto, but deactivate
    const closeFaxModal = () => {
        setIsFaxModalOpen(false);
    };

    // ditto for edit popup
    const openEditModal = (transfer) => {
        setSelectedTransfer(transfer);
        setIsEditModalOpen(true);
    };

    // ditto for closing edit pop up
    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    // download widgets for past faxes
    function PreviousFaxDownloadWidget ({id: faxId}) {
        const buttonStyle = {
            background: "none",
            border: "none",
            padding: 4,
            cursor: "pointer"
        }

        const downloadFaxFile = () => {
            setIsLoadingFax(true);
            miloApi
                .downloadFax(prescriptionId, faxId)
                .finally(() => setIsLoadingFax(false));
        }

        return (
            <div key={faxId}>
                <button style={buttonStyle} onClick={downloadFaxFile}>
                    <Icon icon={'file-text'} size={24} />
                </button>
            </div>
        )
    }

    function SayWhen({moment}) {
        const date = new Date(moment);
        return <>
            {date.toLocaleDateString()}<br />{date.toLocaleTimeString()}
        </>
    }

    const transferHistory = sortedHistory.map(({faxes, transfer, pharmacy}, i) => {

        const faxLoader = <Loader
            type="Puff"
            color="#CCCCCC"
            height={24}
            width={24}
        />

        const twiddlerOrPastFaxes = isLoadingFax
            ? faxLoader
            : faxes.map(PreviousFaxDownloadWidget)

        return (
            <TableRow key={i}>
                <TableCell>
                    <SayWhen moment={transfer.created_at} />
                </TableCell>
                <TableCell>
                    {pharmacy.name}
                </TableCell>
                <TableCell>
                    {pharmacy.street_address}
                </TableCell>
                <TableCell>
                    {formatPhoneNumber(pharmacy.phone)}
                </TableCell>
                <TableCell>
                    {formatPhoneNumber(pharmacy.fax)}
                </TableCell>
                <TableCell>
                    {transfer.sending_pharmacist}
                </TableCell>
                <TableCell>
                    {transfer.receiving_pharmacist}
                </TableCell>
                <TableCell>
                    {twiddlerOrPastFaxes}
                </TableCell>
                <TableCell>
                    <span style={styleForMinCharacters(20)}>
                        {transfer.notes}
                    </span>
                </TableCell>
                <TableCell>
                    <button onClick={() => openFaxModal(transfer)}>Send new fax</button><br/>
                    <button onClick={() => openEditModal(transfer)}>Edit</button>
                </TableCell>
            </TableRow>
        );
    })

    return (
        <div>
            <h3>Transfers</h3>
            <TableContainer component={Paper}>
                <Table style={{ tableLayout: "auto" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Date sent</b></TableCell>
                            <TableCell><b>Pharmacy name</b></TableCell>
                            <TableCell><b>Pharmacy address</b></TableCell>
                            <TableCell><b>Pharmacy phone</b></TableCell>
                            <TableCell><b>Pharmacy fax</b></TableCell>
                            <TableCell><b>Sending pharmacist</b></TableCell>
                            <TableCell><b>Receiving pharmacist</b></TableCell>
                            <TableCell><b>Fax</b></TableCell>
                            <TableCell><b>Notes</b></TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transferHistory}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*dormant pop up for NEW transfer fax*/}
            <FaxModal
                isOpen={isFaxModalOpen}
                onClose={closeFaxModal}
                transfer={selectedTransfer}
            />

            {/*dormant pop up for editing of existing transfert*/}
            <EditModal
                prescription={prescription}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
                transfer={selectedTransfer}
            />
        </div>
    );
};
export default TransferList;
