import moment from "moment"

// provide auth token (on DEV, it's hardkoded - see package.json and/or .env.* files)
export function getCookieValue(name) {
    const developmentCookie = process.env.REACT_APP_DEVELOPMENT_COOKIE

    if (developmentCookie) {
        return developmentCookie
    }

    const value = "; " + document.cookie
    const parts = value.split("; " + name + "=")
    if (parts.length === 2) {
        const cookieString = parts.pop().split(";").shift()
        return JSON.parse(cookieString)
    }
}

// DoB should be YYYY-MM-DD, but raw value is MM/DD/YYYY
export function toServerDate (dateRaw) {
    const date = moment(dateRaw, "MM/DD/YYYY")
    return date.isValid()
        ? date.format("YYYY-MM-DD")
        : null
}

export function toDisplayDate (dateRaw) {
    const date = moment(dateRaw, "YYYY-MM-DD")
    return date.isValid()
        ? date.format('MM/DD/YYYY')
        : null
}
