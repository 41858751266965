import React from "react";

export const humanizeBoolean = (bool) => (bool ? "Yes" : "No");

const BooleanTextField = ({ record, source }) => {
    const value = record[source];
    return <span>{humanizeBoolean(value)}</span>;
};

BooleanTextField.defaultProps = {
    addLabel: true
};

export default BooleanTextField;
