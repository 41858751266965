import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useInput } from "react-admin";
import { FIELD_WIDTH } from "src/config/constants";


/*

In most cases, we recommend using controlled components to implement forms.
In a controlled component, form data is handled by a React component.
The alternative is uncontrolled components, where form data is handled by the DOM itself.

https://reactjs.org/docs/uncontrolled-components.html

*/


// used by:
//  - DrugAutocomplete,
//  - DynamicEnumINput (states etc. selectors),
//  - MergeForm
const TypeaheadInput = (props) => {
    const {
        // autocomplete is just a stateful wrapper for a TextInput -- which needs a label
        label: wrappedInput_label,
        onInputChange, // click suggest
        onChange = () => {}, // type something
        // init. field content & options
        getInitialValue = (formState) => undefined,
        getInitialChoices = null,
        // input can contain arbitrary value, according to MUI manual, whatever it is (arb. in re: to what?)
        allowFreeFormEntry = false,
        // handlers for direct update of 'selection' state, visual and internal.
        getOptionValue = foo => foo,
        getOptionLabel = foo => foo,
        // CSS overrides for the wrapped <Autocomplete/>
        style: autoCompleteStyleOverrides
    } = props;

    // stuff for wrapped component
    // use instead of useField() to create form inputs that have the exact same API as react-admin Input components:
    // (internal RA's hook, wraps react-final-form’s useField() hook. )
    // reference -- https://marmelab.com/react-admin/Inputs.html#the-useinput-hook
    const {
        input: {
            name: wrappedInput_name,
            onChange: onFormChange
        },
        meta: {
            touched: inputState_touched,
            error: inputState_error
        },
        wrappedInput_isRequired
    } = useInput(props);

    const formState = useFormState();
    const autoCompleteInitialValue = getInitialValue(formState.values);

    // state for dropdown content lives here
    const [choicesState, setChoicesState] = useState([]);
    function use_available_data_for_initial_dropdown_content () {
        if (getInitialChoices) {
            getInitialChoices().then((data) => setChoicesState(data));
        }
    }
    // on mount, fill dropdown with stuff if parent passed a handler for this
    useEffect(use_available_data_for_initial_dropdown_content, [getInitialChoices]);

    // Autocomplete provides logic and uses wrapped input as an actual UI element;
    // this wrapped element is described here
    const renderWrappedInput = (params) => {
        // TODO: use explicit typecasting here mb?
        // use error _text_ value
        const hasError = !!(inputState_touched && inputState_error)
        const errorText = inputState_touched && inputState_error
        return <TextField name={wrappedInput_name} label={wrappedInput_label} required={wrappedInput_isRequired} variant="outlined"
            error={hasError} helperText={errorText} style={{ width: FIELD_WIDTH }} {...params} />
    }

    const renderDropdown = (event, value) => onInputChange(value)
        .then(options => setChoicesState(options))

    const useClickedOption = (event, value) => {
        try {
            onFormChange(getOptionValue(value));
        } catch (e) {/*TODO empty `source`*/}

        onChange(value);
    }



    return (
        // reference: https://v4.mui.com/components/autocomplete/,
        //  - !!! choose v4 in the sidebar
        <Autocomplete style={autoCompleteStyleOverrides} defaultValue={autoCompleteInitialValue}
            // TODO implement proper state control for Autocomplete
            //  lest we have a nasty warning like
            //          "Material-UI: A component is changing the default value state of an uncontrolled
            //          Autocomplete after being initialized. To suppress this warning opt
            //          to use a controlled Autocomplete.""
            // https://reactjs.org/docs/uncontrolled-components.html
            // https://v4.mui.com/components/autocomplete/#controllable-states

            // freeSolo (bool): if textbox can contain any arbitrary value.
            freeSolo={allowFreeFormEntry}
            // customize the rendered input.
            // The first argument contains props that you need to forward.
            // Pay specific attention to the `ref` and `inputProps` keys.
            renderInput={renderWrappedInput}
            //  The component has two states that can be controlled:
            //  -   the "input value" state with the inputValue/onInputChange props combination.
            //      (the value displayed in the textbox).
            //  -   the "value" state with the value/onChange props combination.
            //      (the value selected by the user, for instance when pressing Enter).
            onInputChange={renderDropdown}
            onChange={useClickedOption}
            getOptionLabel={getOptionLabel}
            // dropdown options,
            //  -   via useState() above
            options={choicesState} />
    );
};

export default TypeaheadInput;
