import React from "react";
import DynamicEnumInput from "./core/DynamicEnumInput";
import { miloApi } from "src/api";

const UsStateEnum = ({ source }) => {
    return (
        <DynamicEnumInput
            source={source}
            label="State"
            getOptions={() => miloApi.getUsStates()}
        />
    );
};

export default UsStateEnum;
