import urlencode from "urlencode"
import _ from "lodash"
import {flattenPrescription} from './dataHelpers/prescription'

const MIN_QUERY_SIZE_FOR_AUTOCOMPLETE = 3
const FALLBACK_NUMBER_OF_LIST_ROWS = 7

// loading data for autocomplete dropdowns
// - Requests for autocomplete are identified by `filter.q` in 2nd argument
// - no autocomplete for prescriptions (hysterical reasons, I suppose.)
// - we DO autocomplete the rest (patients, prescribers, pharmacies)
export function getAutocompleteUrl (resourceName, { filter: { q: query }={} }={} ) {
    const resourceSupportsAc = resourceName !== 'prescriptions'
    const queryIsAutocompletable = !_.isNil(query)
        && String(query).length >= MIN_QUERY_SIZE_FOR_AUTOCOMPLETE

    return resourceSupportsAc && queryIsAutocompletable
        ? `${resourceName}/v/autocomplete/${urlencode(String(query))}`
        : null
}

export function getListEndpoingCfg (resourceName, params) {
    const filteredUrl = getFilterUrl(resourceName, params)
    const {
        // plain "get a list" URL
        vanillaListUrl,
        sortParameters,
        headers
    } = getVanillaListUrl(resourceName, params)

    const url = `${filteredUrl || vanillaListUrl}${sortParameters}`
    return { url, headers }
}

// loading filtered collections
// Every table view has filter controls above table. There's some crappy magic plumbing
// courtesy of ReactAdmin, but in the end whatever user types ends here.
function getFilterUrl (resourceName, {filter}={}) {

    if (!filter) {
        return null
    }

    const filterMapping = {
        patients : {
            // @TODO this will need date format conversion eventually
            dob : ({dob}) => `by_dob/${dob}`,
            name : ({name}) => `by_name/${name}`
        },
        prescriptions : {
            prescription__serial_number : ({prescription__serial_number : sn}) => `by_serial/${sn}`,
            // @TODO date format conversion
            patient__dob : ({patient__dob : dob}) => `by_dob/${dob}`,
            patient__full_name : ({patient__full_name : name}) => `by_name/${name}`,
            prescriber__full_name : ({prescriber__full_name : name}) => `by_prescriber_name/${name}`
        },
        pharmacies : {
            city : ({city}) => `by_city_or_name/${city}`,
            name : ({name}) => `by_city_or_name/${name}`,
            zipcode : ({zipcode}) => `by_zipcode/${zipcode}`,
            phone : ({phone}) => `by_phone/${phone}`
        },
        prescribers : {
            zipcode : ({zipcode}) => `by_zipcode/${zipcode}`,
            city : ({city}) => `by_city/${city}`,
            // last_name : ({last_name}) => `by_last_name/${last_name}`,
            name : ({name}) => `by_name/${name}`
        }
    }

    // for now, we support ONE filter
    // @TODO add reducer to generate pathparams for several filters at once
    const filterFieldName = _.keys(filter)[0]
    const makePathParams = filterMapping[resourceName][filterFieldName]

    return (makePathParams)
        ? `${resourceName}/${makePathParams(filter)}`
        : null
}

function getVanillaListUrl (
    resourceName,
    {
        sort : {
            field : fieldRaw,
            order : orderRaw
        }={},
        pagination : {
            perPage,
            page
        }={}
    }={}
) {

    const field = fieldRaw?.replace(/^.*?__/, '')
    const order = orderRaw?.toLowerCase()
    const sortParameters = field && order
        ? `?sort_by=${field}&order=${order}`
        : ''

    // warning, 0-based
    const start = !(_.isNil(perPage) || _.isNil(page))
        ? perPage * (page - 1)
        : null
    const end = !_.isNil(start)
        ? start + perPage - 1
        : null
    const headers = !( _.isNil(start) || _.isNil(end))
        ? { 'range' : `${resourceName}=${start}-${end}` }
        : {}

    return {
        vanillaListUrl : `${resourceName}`,
        sortParameters,
        headers
    }
}



// @HACK unwrap resp format incompats of our new API
export function normaliseListPayload ({data : rawData}, resourceName) {
    let data
    if ( rawData.hasOwnProperty('values')
            && _.isArray(rawData.values) ) {
        // @HACK some newer filtration points pack everything into one object
        // and wrap payload array as separate property
        data = rawData.values
    } else if ( rawData.hasOwnProperty('id') ) {
        // @HACK - some filtration endpoints return singular objects, not wrapped in array
        data = [rawData]
    } else {
        // oldschool responce -- json with array
        data = rawData
    }

    // @HACK -- RA's consumers don't like nested objects,
    // so whenever required we make a flat one with prefixed keys
    return (resourceName === 'prescriptions')
        ? _.map(data, flattenPrescription)
        : data
}

// @HACK some newer filtration points pack content-range metadata into JSON response
export function getTotal ({data, headers}) {
    const contentRangeRaw = ensureMeaningfulString(headers, 'content-range')
        || ensureMeaningfulString(data, 'contentRange')
        || ''

    // E.g.: 'content-range: 10-19/234'
    const contentRange = contentRangeRaw.match(/(\d+)-(\d+)\/(\d+)/)
    if (contentRange) {
        // `match` returns a faux-array, so we need to destr it as hash,
        const {'3' : total} = contentRange
        return Number(total)
    } else if (data.length > 0) {
        // nothing has been matched by extraction RE,
        // but data was wrapped in an array
        return data.length
    } else {
        // everything failed, we need at least SOME data to show to the user
        return FALLBACK_NUMBER_OF_LIST_ROWS
    }
}

// bulletproof extraction of truthy-property-as-a-truthy-string
// @TODO make a utility function
function ensureMeaningfulString(obj, propName) {
    const notUndefined = obj.hasOwnProperty(propName) && obj[propName]
    const notNothing = !_.isNull(notUndefined) && !_.isNaN(notUndefined)

    const asString = String(obj[propName]).trim()
    const notEmptyString = asString.length > 0

    return (notUndefined && notNothing && notEmptyString)
        ? asString
        : null
}
