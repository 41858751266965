import { MBM_ADMIN_COOKIE } from "./config/constants"
import { getCookieValue } from "./api/apiHelpers"

// see https://marmelab.com/react-admin/Admin.html#authprovider
const authProvider = {
    // authentication
    login: ({ username, password }) => {
        const body = JSON.stringify({
            email: username,
            password,
            domain: process.env.NODE_ENV === "development" ? "milo.health" : "milorx.com"
        })
        const params = {
            body,
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" })
        }
        const request = new Request( process.env.REACT_APP_MBM_ADMIN_API_URL + "/login", params)

        return fetch(request, { credentials: "include" })
            .then((response) => {
                if (response.status === 403) {
                    throw new AuthFailedError()
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((auth) => {
                localStorage.setItem("auth", JSON.stringify(auth))
                window.location.reload()
            })
            .catch((e) => {
                if (e instanceof AuthFailedError) throw new AuthFailedError("Invalid email or password.")
                throw new Error("Error encountered - " + e.message)
            })
    },
    checkError: (error) => {
        if (error instanceof AuthFailedError) return Promise.reject()
        else Promise.resolve()
    },
    checkAuth: (params) => {
        const cookie = getCookieValue(MBM_ADMIN_COOKIE)
        return cookie
            ? Promise.resolve()
            : Promise.reject()
    },
    logout: () => {
        document.cookie = MBM_ADMIN_COOKIE + "=;max-age=0;domain=.milorx.com;path=/"
        return Promise.resolve()
    },
    getIdentity: () => {
        const identity = JSON.parse(localStorage.getItem("auth"))

        return Promise.resolve({
            id: identity.email,
            fullName: identity.first_name + " " + identity.last_name
        })
    },
    // authorization
    getPermissions: (params) => Promise.resolve()
}

class AuthFailedError extends Error {}

export default authProvider
