import _ from 'lodash'

// referential eq stuff - to prevent unnecessary rerenders
const emptyArray = []
const emptyString = ''

export const initialState = {
    filtered : {
        drugs :         emptyArray,
        forms :         emptyArray,
        dosages :       emptyArray,
        quantities :    emptyArray
    }
}

function getFilters (filters, {name, value}) {
    const [drug, form, dosage, quantity] = Array(4).fill('')
    const resetters = {
        drug :      {drug, form, dosage, quantity},
        form :      {form, dosage, quantity},
        dosage :    {dosage, quantity},
        quantity :  {quantity}
    }
    return {
        ...filters,
        ...resetters[name],
        [name] : value
    }
}

// Rubber meets the road.
function applyFilters ({drugs}, filters) {
    function f(source, filterBy, listNameToExtract) {
        return filters[filterBy].length === 0
            ? emptyArray
            : _.filter(source, x => String(x[filterBy]) === String(filters[filterBy]))[0][listNameToExtract]
    }

    const forms = f(drugs, 'drug', 'forms')
    const dosages = f(forms, 'form', 'dosages')
    const quantities = f(dosages, 'dosage', 'quantities')

    return { drugs, forms, dosages, quantities }
}

export function reducer (state, {type : actionType, payload}) {
    // if (actionType === 'reset') {
    // //  Revert to initial pristine state. Used when updating the multistage
    //     //  drug selector with stuff from another drug search
    //     return initialState
    // } else
    if (actionType === 'init') {
    //  `initialisation` -- we've got fresh packages from server,
        //  we want to populate selects and use defaults.
        const {defaultPackage : filters, drugs} = payload
        const filtered = applyFilters({drugs}, filters)
        const stateWithDrugs = {
            filters,
            filtered
        }
        return stateWithDrugs
    } else if (actionType === 'update') {
    //  fires when user selects something in a dropdown.
        // update old state with new values (not 'replace' -- no unnecessary re-renders)
        const filters = getFilters(state.filters, payload)
        const filtered = applyFilters(state.filtered, filters)
        const updatedState = {
            filters,
            filtered
        }
        return updatedState
    } else {
        throw new Error('MDS reducer dispatched with bad action.')
    }
}


// For future reference - data structure & processing:

/* Data structure:
{
    default<Hash> : {
        drug<Str>,          // "Aspirin"
        form<Str>,          // "pills"
        dosage<Str>,        // "50mg"
        quantity<Str|Num>,  // "20 pills"
        ndc<Str|Int>        // ...for the combination above
    },
    drugs<List<Hash>> : [{
        drug<Str>
        forms<List<Hash>> : [{
            form<Str>,
            dosages<List<Hash>> : [{
                dosage<Str>,
                quantities<List<Hash>> : [{
                    quantity<Int|Str>,
                    ndc<Int|Str>
                }]
            }]
        }]
    }]
}*/

// General idea is like this: initially, we use default values to drill down.
// - map `drugs` -> get list of drugs -> state for 1st dropdown
// - then use default.drug:
//      - value for 1st dropdown
//      - drugs[default.drug] - get list of dosages
// ...et cetera until we have state for all four selects.

// Whenever user changes state, we update sub-state for selects 'below' the one
// being changed. E.g.:
//  - user selects new drug
//  - reset all fields below
//  - plug new list of 'forms'
//  - select gets updated and waits for new input, so forms can be populated.

// Reducer is called to make alterations to the state of 'cascade' filters,
// of which there are several types.
