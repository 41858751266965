import _ from 'lodash'
import {getCookieValue, toServerDate, toDisplayDate} from '../apiHelpers.js'


function getFlattener (prefix) {
    const omitWhenFlattening = [
        // 'medical_conditions',
        // 'current_medications',
        // 'allergies'
    ]
    return function (acc, value, key) {
        const fieldIsCopyable = !_.includes(omitWhenFlattening, key)
        // TODO parametrized separator for flatening of Rx object instead of `__`
        if (fieldIsCopyable) {
            acc[`${prefix}__${key}`] = value
        }
        return acc
    }
}

// @data_massage IN
export function flattenPrescription (data) {

    const canFlatten = _.isObject(data.patient)
        && _.isObject(data.prescriber)
        && _.isObject(data.prescriber)

    if (!canFlatten) {
        return data
    }

    const {patient, prescriber, prescription} = data

    patient.patient_id = patient.id
    prescriber.prescriber_id = prescriber.id
    prescription.prescription_id = prescription.id

    patient.dob = toDisplayDate(data.patient.dob)

    // @MAGIC do not rename
    const __synthetic_fields__ = {
        id: prescription.id,
        patient__full_name: `${patient.last_name}, ${patient.first_name}`,
        prescriber__full_name: `${prescriber.last_name}, ${prescriber.first_name}`,
        __original_prescription_object__: _.clone(data)
    }

    // fully merged and flattened PLUS extra fields
    const dataFlat = _.merge(
        __synthetic_fields__,
        {__synthetic_fields__},
        _.reduce(patient,       getFlattener('patient'),        {}),
        _.reduce(prescriber,    getFlattener('prescriber'),     {}),
        _.reduce(prescription,  getFlattener('prescription'),   {}),
    )

    return dataFlat
}

function getPumper (omittableFields=[]) {
    return function (acc, value, key) {
        const isCopyableField = !_.includes(omittableFields, key)
        if (isCopyableField) {
            _.set(acc, key.split('__'), value)
        }
        return acc
    }
}

// @data_massage OUT
export function pumpPrescription (dataFlat) {
    let dataPumped
    if (dataFlat.__original_prescription_object__) {
    //  dealing with update of an existing prescription
        // we skip some data from 'update' object, fold the rest into the original
        // Rx-object and then it can be submitted
        let omittableFields = _(dataFlat['__synthetic_fields__'])
            .keys()
            .concat(['__synthetic_fields__'])
            .value()

        if (dataFlat.patient__dob) {
            dataFlat.patient__dob = toServerDate(dataFlat.patient__dob)
        }
        dataPumped = _.reduce(dataFlat, getPumper(omittableFields), dataFlat.__original_prescription_object__)
    } else {
    //  dealing with output of a NEW form
        // pretty much as the above, we just don't have original object to update
        // and we need to massage form data into API-appropriate scheme
        let dataPumpedPre = _.reduce(dataFlat, getPumper(), {})
        let {prescription} = dataPumpedPre
        _.unset(dataPumpedPre, 'prescription')
        dataPumped = _.merge(dataPumpedPre, prescription)
    }

    return dataPumped
}
