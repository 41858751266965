import { DateField as ReactAdminDateField } from "react-admin";

const DateField = ReactAdminDateField;

DateField.defaultProps = {
    options: { timeZone: "UTC" },
    addLabel: true
};

export default DateField;
