import React from "react";
import DynamicEnumInput from "./core/DynamicEnumInput";

const GenderEnum = ({ source }) => {
    return (
        <DynamicEnumInput
            source={source}
            label="Gender"
            getOptions={() => Promise.resolve(["male", "female", "other"])}
        />
    );
};

export default GenderEnum;
