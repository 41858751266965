import React, {useState} from "react"
import _ from 'lodash'
import { miloApi } from "src/api"

import { TextField as MuiTextField } from "@material-ui/core"

import { Autocomplete } from "@material-ui/lab"

const AUTOCOMPLETE_STARTS_AT_N_SYMBOLS = 4

// AJAX autocomplete to help user pick a drug
export default function DrugNameAutocomplete ({onChange, value, label, name}) {
    // Input is fully controlled: component's state (2 hooks below) override DOM's source of truth
    // - what user typed into text field
    const [drugNamePart, setDrugNamePart] = useState('');
    // - autocomplete options from backend
    const [nameSuggestions, setNameSuggestions] = useState([])

    const loadDrugs = (drugNamePart) => {

        if (drugNamePart.length < AUTOCOMPLETE_STARTS_AT_N_SYMBOLS ) {
            setNameSuggestions([])
        } else {
            miloApi
                .getDrugNameAutocomplete(drugNamePart)
                .then(massageAndUpdateOptions)
        }

        function massageAndUpdateOptions(drugs) {
            const sorted = _.sortBy(drugs, ['name'])
            const formatted = _.map(sorted, ({name: label, auxInfo: {seoName: value }}) => ({label: label.trim(), value: value.trim()}))
            setNameSuggestions(formatted)
        }

    }

    return (<>
        <Autocomplete

            disableClearable={true}

            value={value}
            onChange={(event, option, reason, stuff) => {
                onChange(option)
            }}

            inputValue={drugNamePart}
            onInputChange={(event, drugNamePart, reason) => {
                loadDrugs(drugNamePart)
                setDrugNamePart(drugNamePart)
            }}

            options={nameSuggestions}

            getOptionLabel={({label}) => label || ''}

            getOptionSelected={(option, value) => option.value === value.value}

            renderInput={(params) => <MuiTextField label={label} variant="filled" {...params}/>}
        />
    </>)
}
