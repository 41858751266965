import React, {useState, useEffect} from "react";
import {
    Create, Edit, Filter, List, Show,
    Datagrid, SimpleShowLayout, SimpleForm, TextField,
    TextInput, BooleanInput
} from "react-admin";
import UsStateEnum from "./widgets/UsStateEnum";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import MultiTextInput from "./widgets/core/MultiTextInput";
import BooleanTextField from "./widgets/BooleanTextField";

// for style overrides
import { makeStyles } from '@material-ui/core/styles';

// The `Datagrid` component uses makeStyles, and supports overriding styles through the `classes` property
const useStylesAlpha = makeStyles({
    headerCell: {
        fontWeight: 'bold',
    },
});

export const PharmacyIcon = LocalPharmacyIcon;

function PharmacyFilters (props) {
    // NB: `alwaysOn` prevents hiding of filter field
    return (<Filter {...props}>
        <TextInput alwaysOn resettable label='Name'    source='name' />
        <TextInput alwaysOn resettable label='City'    source='city'/>
        <TextInput alwaysOn resettable label='Zip'     source='zipcode' />
        <TextInput alwaysOn resettable label='Phone'   source='phone' />
    </Filter>)
}

export const PharmacyList = (props) => {

    const classesForDatagrid = makeStyles({
        headerCell: {
            fontWeight: 'bold',
        },
    })();

    return (
        <List filters={<PharmacyFilters/>} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} {...props} >
            <Datagrid classes={classesForDatagrid} rowClick="show">
                <TextField          source="epid" />
                <TextField          source="name" />
                <TextField          source="chain_type" />
                <TextField          source="street_address" />
                <TextField          source="city" />
                <TextField          source="state" />
                <TextField          source="zip_code" />
                <TextField          source="phone" />
                <TextField          source="fax" />
                <BooleanTextField   source="_24hr" />
            </Datagrid>
        </List>
    );
};

const PharmacyTitle = ({ record }) => {
    return <span>Pharmacy {record.name}</span>;
};

export const PharmacyShow = (props) => (
    <Show title={<PharmacyTitle />} {...props}>
        <SimpleShowLayout>
            <TextField          source="id" />
            <TextField          source="epid" />
            <TextField          source="name" />
            <TextField          source="chain_type" />
            <TextField          source="lat" label="Latitude" />
            <TextField          source="lng" label="Longitude" />
            <TextField          source="street_address" />
            <TextField          source="city" />
            <TextField          source="state" />
            <TextField          source="zip_code" />
            <TextField          source="phone" />
            <TextField          source="fax" />
            <BooleanTextField   source="_24hr" />
        </SimpleShowLayout>
    </Show>
);

export const PharmacyEdit = (props) => (
    <Edit title={<PharmacyTitle />} {...props}>
        <SimpleForm>
            <TextInput    source="id" disabled/>
            <TextInput    source="epid" />
            <TextInput    source="name" />
            <TextInput    source="chain_type" />
            <TextInput    source="lat" label="Latitude" />
            <TextInput    source="lng" label="Longitude" />
            <TextInput    source="street_address" multiline />
            <TextInput    source="city" />
            <UsStateEnum  source="state" />
            <TextInput    source="zip_code" />
            <TextInput    source="phone" />
            <TextInput    source="fax" />
            <BooleanInput source="_24hr" />
        </SimpleForm>
    </Edit>
);

export const PharmacyCreate = (props) => (
    <Create title="Create a Pharmacy" {...props}>
        <SimpleForm>
            <TextInput    source="epid" />
            <TextInput    source="name" />
            <TextInput    source="chain_type" />
            <TextInput    source="lat" label="Latitude" />
            <TextInput    source="lng" label="Longitude" />
            <TextInput    source="street_address" multiline />
            <TextInput    source="city" />
            <UsStateEnum  source="state" />
            <TextInput    source="zip_code" />
            <TextInput    source="phone" />
            <TextInput    source="fax" />
            <BooleanInput source="_24hr" />
        </SimpleForm>
    </Create>
);
