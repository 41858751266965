import { linkToRecord, useRecordContext, useGetOne } from 'react-admin';
import { Link } from 'react-router-dom';

export const PatientLink = (props) => {
    const {
        __original_prescription_object__ : {
            patient : {
                id : patient_id,
                last_name,
                first_name
            }
        }
    } = useRecordContext(props);
    const names = `${last_name}, ${first_name}`
    const {
        data,
        loaded
    } = useGetOne('patients', patient_id);

    const labelStyle = {
        'font-size': '0.7em',
        'color': 'grey'
    }

    const content = (loaded)
        ? <Link to={linkToRecord('/patients', patient_id, 'show')}>{names}</Link>
        : <span>{names}</span>

    return <>
        <span style={labelStyle}>Patient:</span><br/>
        {
            content
        }
    </>
};

export const PrescriberLink = (props) => {
    const {__original_prescription_object__ : { prescriber : {id : prescriber_id, last_name, first_name}}} = useRecordContext(props);
    const { data, loaded } = useGetOne('prescribers', prescriber_id);
    const prescriberDetails = linkToRecord('/prescribers', prescriber_id, 'show');

    if (loaded) {
        // console.log('pat. data', data)
        return <>
            <p style={{'font-size': '0.7em', 'color': 'grey'}}>Prescriber:</p>
            <Link to={prescriberDetails}>
                {
                    `${last_name}, ${first_name}`
                }
            </Link>
        </>
    } else {
        return null
    }
};
