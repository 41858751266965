const transformBody = (axios) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const response = error.response
            if (response) {
                let foo = response && response.data
                    ? response.data
                    : error
                return Promise.reject(foo)
            } else {
                return Promise.reject("API didn't respond")
            }

        }
    )

    return axios
}

export default transformBody
