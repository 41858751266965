import React from 'react'
import _ from 'lodash'

import {Select, InputLabel} from '@material-ui/core'

import { useController } from 'react-hook-form'

export default function MdsStepSelect ({label, name, control, dispatch, state}) {
    const {field: {value, onChange}} = useController({
        name,
        control,
        rules : {
            required : true
        }
    })

    const optionElements = _.map(state, ({[name]:value}) => <option key={String(value)+_.random(1, 1000)} value={value}>{value}</option>)

    function updateFilterState ({target : {value}}) {
        dispatch({
            type: 'update',
            payload : {name, value}
        })
    }

    return (<>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select native id={name} value={value} onChange={updateFilterState}
            disabled={optionElements.length === 0} style={{width : '100%'}}>
            {[
                // <option key='—' value=''>__</option>,
                ...optionElements
            ]}
        </Select>
    </>)
}
