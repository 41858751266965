import { FormControl } from '@material-ui/core';

export default function FormControlStyled ({width='300px', children}) {
    const cssOverride = {
        display: 'block',
        width,
        margin: '32px'
    }
    return <FormControl variant='filled' style={cssOverride}>{children}</FormControl>
}
